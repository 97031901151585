

<template>
  <div class="box">
    <!-- <keep-alive include="UserInfo,ZwdsPP">
    </keep-alive> -->
    <component @ComNameFromSon="SetComName" :is="comName"></component>
    <!-- <UserInfo v-if="USERINFOShow" @SubmitUserinfo="SetUserInfo"></UserInfo>
    <ZwdsPP v-if="ZWPPShow"></ZwdsPP> -->
    <hr />
    <div
      class="thirdLogin"
      v-if="LoginUser.UserId === null || LoginUser.UserId === ''"
    >
      <h3>档案登录</h3>
      <a
        :href="
          this.$webUrl +
          '/Member/Login/WechatLogin?returnUrl=http://zwdsppui.chinazwds.com'
        "
        ><img
          class="wechat-img"
          :src="this.$webUrl + '/Static/images/img08.png'"
          alt=""
      /></a>
    </div>
  </div>
</template>

<style lang="less">
div.thirdLogin {
  margin-top: 2rem;
  text-align: center;

  .wechat-img {
    width: 3rem;
  }
}
</style>
<script>
import { Toast } from "vant";
import UserInfo from "@/components/UserInfo.vue";
import ZwdsPP from "@/components/ZwdsPP.vue";
import Test from "@/components/Test.vue";
import bus from "@/components/Js/EventBus.js";

export default {
  data() {
    return {
      //定义组件名称，用来接受子组件传递过来的值
      comName: "UserInfo", //组件名称 默认为UserInfo
      //定义userinfo的变量，来接受UserInfo组件传过来的值
      UserInfoForChild: {
        Name: "",
        Sex: "", //性别：0-男，1-女
        Birthday: "",
        Hours: 0,
      },
      //定义登录用户的变量
      LoginUser: {
        UserId: "",
        CHName: "",
        Account: "",
      },
      USERINFOShow: false,
      ZWPPShow: true,
    };
  },
  mounted() {
    const access_token = this.$route.query.access_token; //请求类型获取token
    if (access_token) {
      /**
       * 用户登录后需要执行的逻辑
       * 1、检测本地是否有缓存，若无缓存，则需请求认证中心
       * 2、获取token，用得到的token请求认证中心接口，获取用户信息
       * 3、如果用户信息正确，则给本地缓存变量
       */
      // 读取access_token的cookie
      const access_token_cache = this.$cookie.get("access_token");
      if (access_token_cache) {
        // 如果cookie存在，进行缓存处理
        console.log("您已登陆");
      } else {
        this.$nextTick(async function () {
          try {
            const { data: res } = await this.$http({
              headers: {
                "Content-Type": "multipart/form-data",
              },
              method: "post",
              url:
                this.$autherUrl +
                "/Home/GetWechatUser?t=" +
                new Date().getTime(),
              params: {
                access_token: access_token,
              },
            });
            if (res.success) {
              console.log(res.data);
              Toast("登录成功：用户" + res.data.UserId);
            }
            if (!res.success) {
              Toast("业务失败，原因：" + res.msg);
              console.log("业务失败，原因：" + res.msg);
              return false;
            }
          } catch (err) {
            //Toast("发生错误，原因：" + err.message);
          }
        });
      }
    } else {
    }
  },
  methods: {
    // 写入cookie
    setCache() {
      //设置cookies，单位为天
      this.$cookie.set("cookie_name", "cookie_value", { expires: 7 });
      console.log("设置cookie");
    },
    // 删除cookie
    removeCache() {
      this.$cookie.remove("cookie_name");
    },
    readCookie() {
      // 读取cookie
      const value = this.$cookie.get("cookie_name");
      if (value) {
        // 如果cookie存在，进行缓存处理
        console.log("Cookie value:", value);
      }
    },

    //默认展示UserInfo组件
    //点击确认，加载排盘组件
    SetUserInfo(val) {
      console.log("隐藏UserInfo组件,显示排盘组件");
    },
    //设置组件名称
    SetComName(val) {
      this.comName = val;
    },
  },
  //注册组件
  components: {
    Test,
    UserInfo,
    ZwdsPP,
  },
};
</script>