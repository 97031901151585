
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'

//导入样式
import '@/css/common.less'
//import '@/css/index.less'

//注册全局组件

// 导入并安装Vant 组件库
import Vant from 'vant';
import VueRouter from "vue-router";
import 'vant/lib/index.css';

//全局引入cookie
import cookieMethods from '@/components/Js/cookie';
// 将cookie方法挂载到Vue原型上
Vue.prototype.$cookie = cookieMethods

// 定义全局变量
//定义整站地址
const weburl = "http:" + "//www.chinazwds.com";
//const weburl = "http:" + "//mingli.sipiyou.cn";
//const weburl = "http:" + "//localhost:81";
Vue.prototype.$webUrl = weburl;
//定义紫微排盘后端地址
Vue.prototype.$baseUrl = weburl + "/MingLiZwdsBack";
//定义用户中心验证地址
Vue.prototype.$autherUrl = weburl + "/MingLiApi";

// 使用vue-router插件
Vue.use(VueRouter);

Vue.use(Vant);

Vue.config.productionTip = false

Vue.prototype.$http = axios;


//增加一个全局指令
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

// 定义路由配置
const routes = [
  //{ path: '/', component: UserInfo },
  // ...其他路由配置
];

// 创建router实例，并传`routes`配置
const router = new VueRouter({
  mode: 'history', // 使用HTML5 History模式
  routes // 简写，相当于 routes: routes
});

new Vue({
  router, // 将router实例注入到Vue根实例中
  // render 函数中，渲染的事哪个 .VUE组件，那么这个组件就叫做“根组件”
  render: h => h(App),
}).$mount('#app')
